<style scoped>
.c-panel {
  margin: 0px;
  padding: 0px 20px;
}
</style>

<template>
  <el-dialog v-if="m" :title="this.id == 0 ? '添加个码' : '更新个码'" :visible.sync="isShow" width="650px" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog" :show-close="false">
    <div class="vue-box">
      <div class="c-panel">
        <br />
        <el-form size="small" label-width="150px">
          <el-form-item label="商户名称:">
            <el-input v-model="m.mchName" style="width: 350px"></el-input>
          </el-form-item>
          <el-form-item label="限额:">
            <el-input v-model="m.limits" style="width: 350px"></el-input>
          </el-form-item>
          <el-form-item label="是否启用:">
            <el-select v-model="m.status">
              <el-option label="开启" :value="1"></el-option>
              <el-option label="关闭" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="登录二维码:">
            <el-image style="width: 250px; height: 250px" :src="url"></el-image>
          </el-form-item>
          <el-form-item label="备注:">
            <el-input type="text" v-model="m.remarks" style="width: 350px"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-alert title="扫码并确认登录后会自动获取到cookie无需填写" type="info" show-icon> </el-alert>
          </el-form-item>
          <el-form-item label="cookie:">
            <el-input disabled type="textarea" v-model="m.cookie" style="width: 350px"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="(isShow = false), claerData()">取 消</el-button>
      <el-button size="small" type="primary" @click="ok()">操 作</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      id: 0,
      loginid: 0,
      url: "",
      zftlist: [],
      timer: null,
      m: {
        appId: "", // 从菜单配置文件里传递过来的参数
        mhHost: "",
        limits: 5000,
        status: 0,
        remarks: "",
        mchName: "",
        cookie: "",
        type: 1,
        qrLink: ""
      },
      p: {
        // 查询参数
        page: 1,
        limit: 20,
      },
    };
  },
  methods: {
    // 打开
    open: function (data) {
      this.id = data.id;
      this.isShow = true;
      if (this.id == 0 || data == 0) {
        // 如果是添加
        this.id = 0;
      } else {
        this.m.appId = data.appId;
        this.m.mhHost = data.mhHost;
        this.m.limits = data.limits;
        this.m.status = data.status;
        this.m.remarks = data.remarks;
        this.m.mchName = data.mchName;
        this.m.cookie = data.cookie;
      }
      this.get_alipay_login_qr_code();
    },
    // 表单验证
    submit_check: function () {
      return "ok";
    },
    checkQrcode() {
      var defaultCfg = {
        type: "get", //是否是请求体请求
        msg: null,
      };
      this.sa.ajax(
        "http://getcookie.jingyihai.top/zfb_gck.php?act=getcookie&loginid=" + this.loginid,
        function (res) {
          console.log(res);
          if (res.msg == '获取成功') {
            this.m.cookie = res.cookie;
            this.sa.ok("账号登录成功")
            clearInterval(this.timer);
          }
          else if (res.msg == 'loginid不存在，请重新获取二维码') {
            // this.get_alipay_login_qr_code()
            this.sa.ok("登录失败,请刷新页面后重试")
            clearInterval(this.timer);
          }
        }.bind(this), defaultCfg
      );
    },
    get_alipay_login_qr_code() {
      var defaultCfg = {
        type: "get", //是否是请求体请求
        msg: "正在加载登录二维码,请稍等",
      };
      this.sa.ajax(
        "http://getcookie.jingyihai.top/zfb_gck.php?act=getqrcode",
        function (res) {
          console.log(res);
          this.loginid = res.loginid;
          this.url = this.sa.cfg.api_url +
            "/v1/index/enQrcode?url=" + res.qrcodeurl;
        }.bind(this), defaultCfg
      );
      this.timer = setInterval(this.checkQrcode, 2000);
    },
    // 提交
    ok: function () {
      // 表单验证
      if (this.submit_check() != "ok") {
        return;
      }
      // 开始增加或修改
      let self = this;
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      if (this.id == 0) {
        this.sa.ajax(
          "/v1/alipay/cookie/addAlipay",
          this.m,
          function () {
            self.sa.alert("添加成功", function () {
              self.$parent.f5(); // 父视图刷新
              self.isShow = false;
              // self.claerData();
            });
          },
          defaultCfg
        );
      } else {
        // id != 0 为修改
        this.sa.ajax(
          "/v1/alipay/cookie/updateAlipay/" + this.id,
          this.m,
          function () {
            self.sa.alert(
              "修改成功",
              function () {
                self.$parent.f5(); // 父视图刷新
                self.isShow = false;
                self.claerData();
              },
              defaultCfg
            );
          },
          defaultCfg
        );
      }
    },
    claerData() {
      this.m.appId = "";
      this.m.mhHost = "";
      this.m.limits = 5000;
      this.m.status = 0;
      this.m.remarks = "";
      this.m.mchName = "";
      this.m.cookie = "";
      this.loginid = null;
      clearInterval(this.timer);
    },
  },
  created: function () {
  },
};
</script>
